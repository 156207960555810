import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header id="navMenu">
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="/">COVID-19 Staffing Simulator</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/help">Help</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" href="https://youtu.be/Qw41qoU1ofo" style={{display: 'list-item', padding: '0.5rem, 1rem'}} target="_blank">Tutorial</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" href="https://levrum.com/contact/covidinfo" style={{display: 'list-item', padding: '0.5rem, 1rem'}} target="_blank">Request Info</NavLink>
                </NavItem>
                <NavItem style={{paddingLeft: '20px'}}>
                  <a href="https://levrum.com/" style={{display: 'list-item', padding: '0.5rem 1rem'}} target="_blank"><img src="/levrumlogo.svg" alt="Levrum Logo" style={{width: '120px'}}/></a>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
