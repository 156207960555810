import React, { Component } from "react";

import { withStyles, withTheme } from "@material-ui/core";

import c3 from "c3";
import "c3/c3.css";

const styles = (theme) => {};

class DepletionScenariosComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {};

  componentWillUnmount = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.data !== prevProps.data ||
      this.props.renderUuid !== prevProps.renderUuid
    ) {
      this.renderC3Chart();
    }

    if (
      this.props.chartHeight !== prevProps.chartHeight ||
      this.props.chartHeight !== prevProps.chartHeight
    ) {
      this.updateChartSize();
    }
  };

  renderC3Chart = () => {
    const { data, chartHeight, chartWidth, maxStaff } = this.props;

    let days = ["x"];
    let maxX = 0;
    let maxY = Number(maxStaff);
    let chartColumns = [days];

    let worstDay = undefined;
    let bestCaseStaff = 0;
    let worstCaseStaff = 10000000000;

    let depletionMaxY = 0;
    let scenarioNames = ["x"];
    
    let depletionChartColumns = [
      scenarioNames,
      ["Staffing Below 75%"],
      ["Staffing Below 50%"],
      ["Staffing Below 25%"],
    ];

    for (var i = 0; i < data.length; i++) {
      let scenario = data[i];
      let dailyPredictions = scenario.dailyPredictions;
      if (!dailyPredictions) {
        continue;
      }

      if (i === 3) {
        worstDay = scenario.worstStaffingDay;
      }

      bestCaseStaff = Math.max(scenario.minimumStaffAvailable, bestCaseStaff);
      worstCaseStaff = Math.min(scenario.minimumStaffAvailable, worstCaseStaff);

      let column = [];
      column.push(scenario.scenarioLabel.trim());

      scenarioNames.push(scenario.scenarioLabel.trim());
      depletionMaxY = Math.max(depletionMaxY, scenario.daysBelow75PercentStaffing);
      depletionChartColumns[1].push(scenario.daysBelow75PercentStaffing);
      depletionChartColumns[2].push(scenario.daysBelow50PercentStaffing);
      depletionChartColumns[3].push(scenario.daysBelow25PercentStaffing);

      for (var j = 0; j < dailyPredictions.length; j++) {
        let day = dailyPredictions[j];
        maxX = day.ordinalDay;
        if (i === 0) {
          days.push(day.ordinalDay);
        }
        column.push(day.staffAvailable);
      }

      chartColumns.push(column);
    }

    let tickValues = [];
    let divisor = 30;

    for (let i = 1; i <= maxX / divisor; i++) {
      tickValues.push(i * divisor);
    }
    let maxStaffChart = c3.generate({
      bindto: `#maxStaffChart`,
      data: {
        x: "x",
        columns: chartColumns,
        type: "line",
      },
      zoom: {
        enabled: true,
      },
      legend: {
        position: "bottom",
      },
      axis: {
        x: {
          min: 0,
          max: maxX,
          tick: {
            values: tickValues,
          },
          height: 50,
          label: {
            text: "Elapsed Days (0 = First Clinical Presentation)",
            position: "inner-left",
          },
          padding: { left: 0 },
        },
        y: {
          max: maxY,
          min: 0,
          padding: { top: 60, bottom: 0 },
          label: {
            text: "Staff Available",
            position: "outer-middle",
          },
          tick: {
            format: function (d) {
              if (d >= 1000000) {
                return `${d / 1000000}m`;
              } else if (d >= 1000) {
                return `${d / 1000}k`;
              } else {
                return d;
              }
            },
          },
        },
      },
      point: {
        show: false,
      },
      grid: {
        x: {
          show: true,
          lines: [
            { value: worstDay, text: "Worst Staffing Day", position: "end" },
          ],
        },
        y: {
          show: true,
          lines: [
            {
              value: bestCaseStaff,
              class: "min",
              text: "Best Case",
              position: "start",
            },
            {
              value: worstCaseStaff,
              class: "max",
              text: "Worst Case",
              position: "start",
            },
          ],
        },
      },
      size: {
        height: chartHeight / 2,
        width: chartWidth,
      },
      padding: {
        top: 10,
        bottom: -20,
      },
      tooltip: {
        format: {
          title: function (d) {
            return `Day ${d}`;
          },
          value: function (value, ratio, id) {
            if (value >= 1000000) {
              return `${Number(value / 1000000).toFixed(2)}m`;
            } else if (value >= 1000) {
              return `${Number(value / 1000).toFixed(2)}k`;
            } else {
              return value;
            }
          },
        },
      },
    });
    let depletionDaysChart = c3.generate({
      bindto: `#depletionDaysChart`,
      data: {
        x: "x",
        columns: depletionChartColumns,
        type: "bar",
      },
      zoom: {
        enabled: true,
      },
      legend: {
        position: "bottom",
      },
      axis: {
        x: {
          type: "category",
        },
        y: {
          max: depletionMaxY * 1.10,
          min: 0,
          padding: { top: 10, bottom: 0 },
          label: {
            text: "Number of Days",
            position: "outer-middle",
          }
        },
      },
      size: {
        height: chartHeight / 2,
        width: chartWidth,
      },
      padding: {
        top: 10,
        bottom: 0,
      },
      tooltip: {
        format: {
          title: function (d) {
            return `Day ${d}`;
          },
          value: function (value, ratio, id) {
            if (value >= 1000000) {
              return `${Number(value / 1000000).toFixed(2)}m`;
            } else if (value >= 1000) {
              return `${Number(value / 1000).toFixed(2)}k`;
            } else {
              return value;
            }
          },
        },
      },
    });
    this.setState({ maxStaffChart: maxStaffChart });
  };

  updateChartSize = () => {
    if (this.state.maxStaffChart !== undefined) {
      this.state.maxStaffChart.resize({
        height: this.props.chartHeight,
        width: this.props.chartWidth,
      });
    }
  };

  render = () => {
    return (
      <React.Fragment>
        <div id="maxStaffChart" />
        <div id="depletionDaysChart" />
      </React.Fragment>
    );
  };
}

export const DepletionScenarios = withStyles(styles)(
  withTheme(DepletionScenariosComponent)
);

export default DepletionScenarios;
