import React, { Component } from "react";

import {
  withStyles,
  withTheme,
  TableCell,
  TableRow,
  Button,
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";

import { v4 as uuidv4 } from "uuid";

const styles = (theme) => ({
  dataTableContainer: {
    marginRight: "40px",
    padding: "4px"
  },
  statsTable: {
    marginBottom: "4px"
  },
  rightAlignCell: {
    textAlign: "right"
  }
});

const displayedScenarioColumns = {
  Scenario: true,
  "Peak Daily Transports": true,
  "Peak Transports Day": true,
  "Min Staff Avail.": true,
  "Min Staffing %": true,
  "Worst Staffing Day": true,
  "Days < 75%": true,
  "Days < 50%": true,
  "Days < 25%": true,
  "Show Details": true,
};

const displayedDetailColumns = {
  Day: true,
  Contagious: true,
  "Total Recovered": true,
  "Total Deceased": true,
  "New Cases": true,
  "New Recoveries": true,
  "New Deaths": true,
  "Total Affected": true,
  Transports: true,
  "Staff Exposures": true,
  "Staff Available": true,
  "Staff Unavailable": true,
  "Staff Recovered": true,
  "Staff New Cases": true,
  "Staff New Recoveries": true,
};

function ScenarioStatsRow(props) {
  const {
    classes,
    onRowClick,
    scenarioName,
    peakDailyTransports,
    peakTransportsDay,
    minStaffAvailable,
    minStaffPercentage,
    worstStaffingDay,
    daysBelow75,
    daysBelow50,
    daysBelow25,
    numDetails,
    detailsIndex,
  } = props;

  const onClick = () => {
    if (onRowClick) {
      onRowClick(
        [
          scenarioName,
          peakDailyTransports,
          peakTransportsDay,
          minStaffAvailable,
          minStaffPercentage,
          worstStaffingDay,
          daysBelow75,
          daysBelow50,
          daysBelow25,
          numDetails,
          detailsIndex,
        ],
        detailsIndex
      );
    }
  };

  return (
    <TableRow hover key={scenarioName} onClick={onClick}>
      {displayedScenarioColumns["Scenario"] && (
        <TableCell size="small">{scenarioName}</TableCell>
      )}
      {displayedScenarioColumns["Peak Daily Transports"] && (
        <TableCell size="small" className={classes.rightAlignCell}>{peakDailyTransports}</TableCell>
      )}
      {displayedScenarioColumns["Peak Transports Day"] && (
        <TableCell size="small" className={classes.rightAlignCell}>{peakTransportsDay}</TableCell>
      )}
      {displayedScenarioColumns["Min Staff Avail."] && (
        <TableCell size="small" className={classes.rightAlignCell}>{minStaffAvailable}</TableCell>
      )}
      {displayedScenarioColumns["Min Staffing %"] && (
        <TableCell size="small" className={classes.rightAlignCell}>{minStaffPercentage}</TableCell>
      )}
      {displayedScenarioColumns["Worst Staffing Day"] && (
        <TableCell size="small" className={classes.rightAlignCell}>{worstStaffingDay}</TableCell>
      )}
      {displayedScenarioColumns["Days < 75%"] && (
        <TableCell size="small" className={classes.rightAlignCell}>{daysBelow75}</TableCell>
      )}
      {displayedScenarioColumns["Days < 50%"] && (
        <TableCell size="small" className={classes.rightAlignCell}>{daysBelow50}</TableCell>
      )}
      {displayedScenarioColumns["Days < 25%"] && (
        <TableCell size="small" className={classes.rightAlignCell}>{daysBelow25}</TableCell>
      )}
      {displayedScenarioColumns["Show Details"] && (
        <TableCell size="small">
          <Button style={{ marginLeft: "-8px" }}>Click Here</Button>
        </TableCell>
      )}
    </TableRow>
  );
}

function DetailsRow(props) {
  const {
    classes,
    day,
    contagious,
    recovered,
    deceased,
    newCasesPop,
    newRecoveriesPop,
    newDeathsPop,
    popCumAffected,
    responses,
    staffExposures,
    staffAvailable,
    staffOutOfService,
    staffRecovered,
    newCasesStaff,
    newRecoveriesStaff
  } = props;

  return (
    <TableRow hover key={day}>
      {displayedDetailColumns["Day"] && <TableCell size="small" className={classes.rightAlignCell}>{day}</TableCell>}
      {displayedDetailColumns["Contagious"] && <TableCell size="small" className={classes.rightAlignCell}>{contagious}</TableCell>}
      {displayedDetailColumns["Total Recovered"] && <TableCell size="small" className={classes.rightAlignCell}>{recovered}</TableCell>}
      {displayedDetailColumns["Total Deceased"] && <TableCell size="small" className={classes.rightAlignCell}>{deceased}</TableCell>}
      {displayedDetailColumns["New Cases"] && <TableCell size="small" className={classes.rightAlignCell}>{newCasesPop}</TableCell>}
      {displayedDetailColumns["New Recoveries"] && <TableCell size="small" className={classes.rightAlignCell}>{newRecoveriesPop}</TableCell>}
      {displayedDetailColumns["New Deaths"] && <TableCell size="small" className={classes.rightAlignCell}>{newDeathsPop}</TableCell>}
      {displayedDetailColumns["Total Affected"] && <TableCell size="small" className={classes.rightAlignCell}>{popCumAffected}</TableCell>}
      {displayedDetailColumns["Transports"] && <TableCell size="small" className={classes.rightAlignCell}>{responses}</TableCell>}
      {displayedDetailColumns["Staff Exposures"] && <TableCell size="small" className={classes.rightAlignCell}>{staffExposures}</TableCell>}
      {displayedDetailColumns["Staff Available"] && <TableCell size="small" className={classes.rightAlignCell}>{staffAvailable}</TableCell>}
      {displayedDetailColumns["Staff Unavailable"] && <TableCell size="small" className={classes.rightAlignCell}>{staffOutOfService}</TableCell>}
      {displayedDetailColumns["Staff Recovered"] && <TableCell size="small" className={classes.rightAlignCell}>{staffRecovered}</TableCell>}
      {displayedDetailColumns["Staff New Cases"] && <TableCell size="small" className={classes.rightAlignCell}>{newCasesStaff}</TableCell>}
      {displayedDetailColumns["Staff New Recoveries"] && <TableCell size="small" className={classes.rightAlignCell}>{newRecoveriesStaff}</TableCell>}
    </TableRow>
  );
}

class ScenarioStatsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scenarioData: [],
      displayDetails: false,
    };
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.data !== prevProps.data ||
      this.props.renderUuid !== prevProps.renderUuid
    ) {
      this.renderTable();
    }

    if (
      this.props.chartHeight !== prevProps.chartHeight ||
      this.props.chartWidth !== prevProps.chartWidth
    ) {
      this.resizeTable();
    }
  };

  resizeTable = () => {
    const { chartHeight, chartWidth } = this.props;
    let container = document.getElementById("dataTableContainer");
    if (!container) return;

    container.style.height = chartHeight;
    container.style.width = chartWidth;

    let statsTable = document.getElementById("statsTable");
    if (!statsTable) return;
    statsTable.style.height = chartHeight;
    statsTable.style.width = chartWidth;
  };

  renderTable = () => {
    const { data } = this.props;

    let scenarioData = [];
    for (let i = 0; i < data.length; i++) {
      let scenario = data[i];
      let row = [];

      row.push(scenario.scenarioLabel.trim());
      row.push(scenario.peakDailyTransports.toFixed(0));
      row.push(scenario.peakDailyTransportsDay);
      row.push(scenario.minimumStaffAvailable.toFixed(0));
      row.push(scenario.minimumStaffPercentage.toFixed(2));
      row.push(scenario.worstStaffingDay);
      row.push(scenario.daysBelow75PercentStaffing);
      row.push(scenario.daysBelow50PercentStaffing);
      row.push(scenario.daysBelow25PercentStaffing);
      row.push(i);
      scenarioData.push(row);
    }

    this.setState({ scenarioData: scenarioData });
  };

  scenarioOptions = {
    filter: false,
    selectableRows: "none",
    customRowRender: (data) => {
      const [
        scenarioName,
        peakDailyTransports,
        peakTransportsDay,
        minStaffAvailable,
        minStaffPercentage,
        worstStaffingDay,
        daysBelow75,
        daysBelow50,
        daysBelow25,
        detailsIndex,
      ] = data;
      return (
        <ScenarioStatsRow
          key={scenarioName}
          classes={this.props.classes}
          scenarioName={scenarioName}
          peakDailyTransports={peakDailyTransports}
          peakTransportsDay={peakTransportsDay}
          minStaffAvailable={minStaffAvailable}
          minStaffPercentage={minStaffPercentage}
          worstStaffingDay={worstStaffingDay}
          daysBelow75={daysBelow75}
          daysBelow50={daysBelow50}
          daysBelow25={daysBelow25}
          detailsIndex={detailsIndex}
          onRowClick={this.onRowClick}
        />
      );
    },
    onColumnViewChange: (column, action) => {
      displayedScenarioColumns[column] = action === "add" ? true : false;
      this.setState({ renderUuid: uuidv4() });
    },
  };

  detailOptions = {
    selectableRows: "none",
    customRowRender: (data) => {
      const [
        day,
        contagious,
        recovered,
        deceased,
        newCasesPop,
        newRecoveriesPop,
        newDeathsPop,
        popCumAffected,
        responses,
        staffExposures,
        staffAvailable,
        staffOutOfService,
        staffRecovered,
        newCasesStaff,
        newRecoveriesStaff,
      ] = data;
      return (
        <DetailsRow
          classes={this.props.classes}
          key={day}
          day={day}
          contagious={contagious}
          recovered={recovered}
          deceased={deceased}
          newCasesPop={newCasesPop}
          newRecoveriesPop={newRecoveriesPop}
          newDeathsPop={newDeathsPop}
          popCumAffected={popCumAffected}
          responses={responses}
          staffExposures={staffExposures}
          staffAvailable={staffAvailable}
          staffOutOfService={staffOutOfService}
          staffRecovered={staffRecovered}
          newCasesStaff={newCasesStaff}
          newRecoveriesStaff={newRecoveriesStaff}
        />
      );
    },
    onColumnViewChange: (column, action) => {
      displayedDetailColumns[column] = action === "add" ? true : false;
      this.setState({renderUuid: uuidv4() });
    },
  };

  onRowClick = (row, detailsIndex) => {
    let data = this.props.data;
    let scenario = data ? data[detailsIndex] : undefined;
    if (!scenario) return;
    let detailData = this.makeDetailData(row, scenario.dailyPredictions);
    this.setState({
      displayDetails: true,
      detailData: detailData,
      detailScenarioName: row[0],
    });
  };

  makeDetailData = (row, details) => {
    let detailData = [];
    for (var i = 0; i < details.length; i++) {
      let row = [];
      row.push(details[i].ordinalDay);
      row.push(details[i].popContagious.toFixed(0));
      row.push(details[i].popRecovered.toFixed(0));
      row.push(details[i].popDeceased.toFixed(0));
      row.push(details[i].newCasesPop.toFixed(0));
      row.push(details[i].newRecoveriesPop.toFixed(0));
      row.push(details[i].newDeathsPop.toFixed(0));
      row.push(details[i].popCumAffected.toFixed(0));
      row.push(details[i].responses.toFixed(0));
      row.push(details[i].staffExposures.toFixed(0));
      row.push(details[i].staffAvailable.toFixed(0));
      row.push(details[i].staffOutOfService.toFixed(0));
      row.push(details[i].staffRecovered.toFixed(0));
      row.push(details[i].newCasesStaff.toFixed(0));
      row.push(details[i].newRecoveriesStaff.toFixed(0));
      detailData.push(row);
    }
    return detailData;
  };

  getScenarioColumns = () => {
    return [
      {
        name: "Scenario",
        options: {
          display: displayedScenarioColumns["Scenario"].toString(),
        },
      },
      {
        name: "Peak Daily Transports",
        options: {
          display: displayedScenarioColumns["Peak Daily Transports"].toString(),
        },
      },
      {
        name: "Peak Transports Day",
        options: {
          display: displayedScenarioColumns["Peak Transports Day"].toString(),
        },
      },
      {
        name: "Min Staff Avail.",
        options: {
          display: displayedScenarioColumns["Min Staff Avail."].toString(),
        },
      },
      {
        name: "Min Staffing %",
        options: {
          display: displayedScenarioColumns["Min Staffing %"].toString(),
        },
      },
      {
        name: "Worst Staffing Day",
        options: {
          display: displayedScenarioColumns["Worst Staffing Day"].toString(),
        },
      },
      {
        name: "Days < 75%",
        options: {
          display: displayedScenarioColumns["Days < 75%"].toString(),
        },
      },
      {
        name: "Days < 50%",
        options: {
          display: displayedScenarioColumns["Days < 50%"].toString(),
        },
      },
      {
        name: "Days < 25%",
        options: {
          display: displayedScenarioColumns["Days < 25%"].toString(),
        },
      },
      {
        name: "Show Details",
        options: {
          display: displayedScenarioColumns["Show Details"].toString(),
        },
      },
      {
        name: "Details",
        options: {
          display: "excluded",
          download: false,
        },
      },
    ];
  };

  getDetailsColumns = () => {
    return [
      {
        name: "Day",
        options: {
          display: displayedDetailColumns["Day"].toString(),
        },
      },
      {
        name: "Contagious",
        options: {
          display: displayedDetailColumns["Contagious"].toString(),
        },
      },
      {
        name: "Total Recovered",
        options: {
          display: displayedDetailColumns["Total Recovered"].toString(),
        },
      },
      {
        name: "Total Deceased",
        options: {
          display: displayedDetailColumns["Total Deceased"].toString(),
        },
      },
      {
        name: "New Cases",
        options: { display: displayedDetailColumns["New Cases"].toString() },
      },
      {
        name: "New Recoveries",
        options: {
          display: displayedDetailColumns["New Recoveries"].toString(),
        },
      },
      {
        name: "New Deaths",
        options: { display: displayedDetailColumns["New Deaths"].toString() },
      },
      {
        name: "Total Affected",
        options: {
          display: displayedDetailColumns["Total Affected"].toString(),
        },
      },
      {
        name: "Transports",
        options: { display: displayedDetailColumns["Transports"].toString() },
      },
      {
        name: "Staff Exposures",
        options: {
          display: displayedDetailColumns["Staff Exposures"].toString(),
        },
      },
      {
        name: "Staff Available",
        options: {
          display: displayedDetailColumns["Staff Available"].toString(),
        },
      },
      {
        name: "Staff Unavailable",
        options: {
          display: displayedDetailColumns["Staff Unavailable"].toString(),
        },
      },
      {
        name: "Staff Recovered",
        options: {
          display: displayedDetailColumns["Staff Recovered"].toString(),
        },
      },
      {
        name: "Staff New Cases",
        options: {
          display: displayedDetailColumns["Staff New Cases"].toString(),
        },
      },
      {
        name: "Staff New Recoveries",
        options: {
          display: displayedDetailColumns["Staff New Recoveries"].toString(),
        },
      },
    ];
  };

  render = () => {
    const { classes } = this.props;
    const {
      scenarioData,
      detailData,
      displayDetails,
      detailScenarioName,
      renderUuid,
    } = this.state;

    let sColumns = this.getScenarioColumns();
    let dColumns = this.getDetailsColumns();

    return (
      <div
        id="dataTableContainer"
        className={classes.dataTableContainer}
        key={renderUuid}
      >
        {!displayDetails && (
          <MUIDataTable
            id="statsTable"
            className={classes.statsTable}
            title={"Scenario Statistics"}
            data={scenarioData}
            columns={sColumns}
            options={this.scenarioOptions}
          />
        )}
        {displayDetails && (
          <React.Fragment>
            <MUIDataTable
              id="detailsTable"
              classes={classes.detailsTable}
              title={`Scenario Details for "${detailScenarioName}"`}
              data={detailData}
              columns={dColumns}
              options={this.detailOptions}
            />
            <Button
              onClick={() => {
                this.setState({ displayDetails: false });
              }}
              variant="contained"
              style={{ marginTop: "16px", marginBottom: "8px" }}
            >
              Return To Overview
            </Button>
          </React.Fragment>
        )}
      </div>
    );
  };
}

export const ScenarioStats = withStyles(styles)(
  withTheme(ScenarioStatsComponent)
);

export default ScenarioStats;
