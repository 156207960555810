import React from "react";

function Disclaimer()  {
  return (<div><b>DISCLAIMER:   This is beta software.</b>   The algorithms in it are based on our best understanding of published literature on COVID-19 [1][2][3].   We are not epidemiologists, and our implementation has not been reviewed by epidemiologists. We recommend that you use this tool to test a wide range of possible scenarios with different parameter settings, and not as a tool to predict specific outcomes.   By using this site, you acknowledge that this is a free, un-supported, best-effort attempt that may or may not provide accurate predictive information, and that Levrum provides no warranty, express or implied, as to the accuracy, utility or fitness for particular purpose of this tool.
    <br/><br/>
    References<br/>
    <ul>[1]	Aylward B, et al.   “Report of the WHO-China joint mission on coronavirus (COVID-19).”   World Health Authority.   Web:  https://www.who.int/docs/default-source/coronaviruse/who-china-joint-mission-on-covid-19-final-report.pdf,    03-23-2020.</ul>
    <ul>[2]	Pueyo T.   “Coronavirus: why you must act now.”  </ul>
    <ul>[3]	Meyers LM et al.  “COVID-19 Healthcare Demand Projections: Austin, Texas”.   03-24-2020 Presentation to the City of Austin.</ul>
    </div>);
}

export default Disclaimer;