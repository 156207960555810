import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Disclaimer from "./Disclaimer";
import DailyDataChart from "./DailyDataChart";
import CallVolumeChart from "./CallVolumeChart";
import DepletionScenarios from "./DepletionScenarios";
import ScenarioStats from "./ScenarioStats";

import Api from "../services/Api";

import {
  opsDataLabels,
  opsDataValues,
  popDataLabels,
  popDataValues,
  presets,
  defaultSettings,
  menuProps,
} from "../services/AppConsts";

import c3 from "c3";
import "c3/c3.css";

import { v4 as uuidv4 } from "uuid";

import {
  withStyles,
  withTheme,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
  NativeSelect,
  MenuItem,
  Select,
  Input,
  Button,
  Typography,
  Collapse,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import { DeletableChip } from "./DeletableChip";

const styles = (theme) => ({
  container: {
    display: "inline-flex",
    flexDirection: "rows",
    flex: "auto",
    width: "99%",
  },
  tabSelectorContainer: {
    paddingTop: "4px",
    display: "flex",
  },
  tabsContainer: {
    overflowY: "auto"
  },
  controlsContainer: {
    minWidth: "240px",
    maxWidth: "270px",
    paddingLeft: "20px",
    overflow: "auto",
  },
  controls: {
    minWidth: "200px",
    maxWidth: "250px",
    paddingRight: "20px",
  },
  control: {
    paddingBottom: "8px",
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  disclaimerPopup: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    height: "100px",
    width: "100%",
    backgroundColor: "#eeff00ca",
  },
  disclaimerText: {},
  disclaimerDiv: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translateY(-50%) translateX(50%)",
    display: "inline-flex",
    flex: "auto 0 1",
    flexDirection: "row",
  },
  disclaimerLink: {
    color: "#ff0000",
  },
  disclaimerCloseButton: {
    position: "absolute",
    right: "0%",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "1.5rem",
    fontWeight: "bold",
    padding: "0px 0px",
    paddingLeft: "16px",
    paddingRight: "16px",
    marginRight: "16px",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function makeTabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class HomeComponent extends Component {
  constructor(props) {
    super(props);

    let stateSet = false;
    if (
      props.match &&
      props.match.params &&
      props.match.params.encodedOptions
    ) {
      try {
        let state = JSON.parse(atob(props.match.params.encodedOptions));
        if (typeof state === "object" && state !== null) {
          this.state = state;
          stateSet = true;
        }
      } catch {}
    }

    let settingsJsonString = localStorage.getItem("settings");
    if (settingsJsonString !== null || settingsJsonString !== undefined) {
      try {
        let settings = JSON.parse(settingsJsonString);
        this.state = {
          attackRate: settings.attackRate,
          doublingIntervalDays: settings.doublingIntervalDays,
          emsContagionRate: settings.emsContagionRate,
          recoveryDays: settings.recoveryDays,
          transportRate: settings.transportRate,
          servicePopulation: settings.servicePopulation,
          emsStaffCount: settings.emsStaffCount,
          selectedTab: settings.selectedTab,
          selectedPopData: settings.selectedPopData,
          preset: settings.preset,
          selectedOpsData: settings.selectedOpsData,
          showDisclaimerPopup: true,
        };
        stateSet = true;
      } catch {}
    }

    if (!stateSet) {
      this.state = {
        attackRate: defaultSettings.attackRate,
        doublingIntervalDays: defaultSettings.doublingIntervalDays,
        emsContagionRate: defaultSettings.emsContagionRate,
        recoveryDays: defaultSettings.recoveryDays,
        transportRate: defaultSettings.transportRate,
        servicePopulation: defaultSettings.servicePopulation,
        emsStaffCount: defaultSettings.emsStaffCount,
        selectedTab: defaultSettings.selectedTab,
        selectedPopData: defaultSettings.selectedPopData.slice(),
        preset: defaultSettings.preset,
        selectedOpsData: defaultSettings.selectedOpsData.slice(),
        showDisclaimerPopup: true,
      };
    }

    let hideDisclaimer = localStorage.getItem("hideDisclaimer");
    if (Boolean(hideDisclaimer)) {
      this.state.showDisclaimerPopup = false;
    }
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resizeComponents);
    this.updateChartData();
    this.resizeComponents();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.attackRate != prevState.attackRate ||
      this.state.doublingIntervalDays != prevState.doublingIntervalDays ||
      this.state.servicePopulation != prevState.servicePopulation ||
      this.state.recoveryDays != prevState.recoveryDays ||
      this.state.transportRate != prevState.transportRate ||
      this.state.emsContagionRate != prevState.emsContagionRate ||
      this.state.emsStaffCount != prevState.emsStaffCount ||
      this.state.selectedOpsData != prevState.selectedOpsData ||
      this.state.selectedPopData != prevState.selectedPopData
    ) {
      this.updateChartData();
    }

    if (this.state.selectedTab !== prevState.selectedTab) {
      if (this.state.selectedTab === 0) {
        this.setState({ dailyDataUuid: uuidv4() });
      } else if (this.state.selectedTab === 1) {
        this.setState({ callVolumeUuid: uuidv4() });
      } else if (this.state.selectedTab === 2) {
        this.setState({ depletionScenariosUuid: uuidv4() });
      } else if (this.state.selectedTab === 3) {
        this.setState({ scenarioStatsUuid: uuidv4() });
      }
      this.updateLocalStorageSettings();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeComponents);
  };

  resizeComponents = () => {
    let chartHeight = this.getChartHeight();
    let chartWidth = window.innerWidth * 0.95 - 300;

    // const tabSelectorContainer = document.getElementById("tabSelectorContainer");
    // chartWidth = Boolean(tabSelectorContainer) ? Math.max(chartWidth, tabSelectorContainer.offsetWidth) : chartWidth;

    const tabsContainer = document.getElementById("tabsContainer");
    tabsContainer.style.height = `${chartHeight}px`;
    tabsContainer.style.width = `${chartWidth}px`;

    const controlsContainer = document.getElementById("controlsContainer");
    controlsContainer.style.height = `${chartHeight + 40}px`;

    this.setState({ chartHeight: chartHeight, chartWidth: chartWidth });
  };

  getChartHeight = () => {
    var navMenuHeight = 0;
    const navMenu = document.getElementById("navMenu");
    if (navMenu) navMenuHeight = navMenu.offsetHeight;

    const tabSelector = document.getElementById("tabSelectorContainer");
    if (tabSelector) navMenuHeight += tabSelector.offsetHeight;

    return window.innerHeight - navMenuHeight - 40;
  };

  hasValidParameters = () => {
    let result = true;
    result =
      result &&
      this.state.attackRate !== null &&
      this.state.attackRate !== undefined &&
      Number(this.state.attackRate) > 0;
    result =
      result &&
      this.state.doublingIntervalDays !== null &&
      this.state.doublingIntervalDays !== undefined &&
      Number(this.state.doublingIntervalDays) >= 1;
    result =
      result &&
      this.state.emsStaffCount !== null &&
      this.state.emsStaffCount !== undefined &&
      Number(this.state.emsStaffCount) >= 1;
    result =
      result &&
      this.state.servicePopulation !== null &&
      this.state.servicePopulation !== undefined &&
      Number(this.state.servicePopulation) > 1;
    result =
      result &&
      this.state.transportRate !== null &&
      this.state.transportRate !== undefined;
    result =
      result &&
      this.state.emsContagionRate !== null &&
      this.state.emsContagionRate !== undefined;
    result =
      result &&
      this.state.recoveryDays !== null &&
      this.state.recoveryDays !== undefined &&
      Number(this.state.recoveryDays) > 0;
    return result;
  };

  resetSettings = () => {
    this.setState(defaultSettings);
  };

  updateLocalStorageSettings = () => {
    let stringifiedSettings = this.getStringifiedSettings();
    window.localStorage.setItem("settings", stringifiedSettings);
  };

  updateChartData = async () => {
    if (!this.hasValidParameters()) {
      return;
    }

    let data = await Api.getData(
      "Alg-Pop-200325",
      this.state.attackRate / 100,
      this.state.doublingIntervalDays,
      this.state.emsContagionRate / 100,
      this.state.emsStaffCount,
      this.state.recoveryDays,
      this.state.servicePopulation,
      this.state.transportRate / 100
    );

    let dailyData = data !== null ? data.dailyData : undefined;
    let summaryData = data !== null ? data.summaryData : undefined;

    this.updateLocalStorageSettings();

    this.setState({ dailyData: dailyData, summaryData: summaryData });
  };

  settingChanged = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    let preset = presets[this.state.preset];
    if (
      preset === undefined ||
      (preset[event.target.id] !== undefined &&
        preset[event.target.id] !== event.target.value)
    ) {
      this.setState({ preset: "custom" });
    }
  };

  selectedOpsDataChanged = (event) => {
    this.setState({ selectedOpsData: event.target.value });
  };

  selectedPopDataChanged = (event) => {
    this.setState({ selectedPopData: event.target.value });
  };

  presetChanged = (event) => {
    let value = event.target.value;
    this.setState(presets[value]);
  };

  getStyles = (item, items, theme) => {
    if (items === null || items === undefined) {
      return { fontWeight: theme.typography.fontWeightRegular };
    }

    return {
      fontWeight:
        items.indexOf(item) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  removeFromSelectedOpsData = (value) => {
    var newSelection = this.state.selectedOpsData.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.setState({ selectedOpsData: newSelection });
  };

  removeFromSelectedPopData = (value) => {
    var newSelection = this.state.selectedPopData.slice();
    if (newSelection.indexOf(value) === -1) return;

    newSelection.splice(newSelection.indexOf(value), 1);
    this.setState({ selectedPopData: newSelection });
  };

  getStringifiedSettings = () => {
    let stateCopy = Object.assign({}, this.state);
    delete stateCopy.chartColumns;
    delete stateCopy.c3chart;
    delete stateCopy.dailyData;
    delete stateCopy.summaryData;
    
    let stringifiedSettings = JSON.stringify(stateCopy);
    return stringifiedSettings;
  };

  copyLinkToChart = () => {
    let encodedOptions = btoa(this.getStringifiedSettings());

    navigator.clipboard.writeText(
      `https://covidsim.levrum.com/${encodedOptions}`
    );
  };

  selectedTabChanged = (event, value) => {
    this.setState({ selectedTab: value });
  };

  showDisclaimer = () => {
    this.setState({ showDisclaimerDialog: true });
  };

  hideDisclaimer = () => {
    this.setState({ showDisclaimerPopup: false });
    localStorage.setItem("hideDisclaimer", "true");
  };

  render = () => {
    const { classes, theme } = this.props;
    const {
      preset,
      attackRate,
      doublingIntervalDays,
      emsContagionRate,
      emsStaffCount,
      recoveryDays,
      servicePopulation,
      transportRate,
      selectedOpsData,
      selectedPopData,
      dailyData,
      summaryData,
      chartHeight,
      chartWidth,
      selectedTab,
      dailyDataUuid,
      callVolumeUuid,
      depletionScenariosUuid,
      scenarioStatsUuid
    } = this.state;

    const getTabButtonStyle = (index) => {
      return {
        backgroundColor: selectedTab === index ? "black" : "transparent",
        width: "100%",
        height: "2px",
        marginTop: "4px",
      };
    };

    const servicePopulationTooltip = "People in jurisdiction";
    const doublingIntervalTooltip =
      "Initial doubling period (before damping takes effect)";
    const attackRateTooltip = "Estimated population penetration rate";
    const recoveryDaysTooltip = "How long it takes the typical case to recover";
    const transportRateTooltip = "Percentage of cases requiring transport";
    const emsStaffCountTooltip = "Number of EMS staff in jurisdiction";
    const emsContagionRateTooltip =
      "Percentage of transports resulting in EMS staff contagion";

    return (
      <div className={classes.container}>
        <div id="tabViewContainer" className={classes.tabViewContainer}>
          <div id="tabsContainer" className={classes.tabsContainer}>
            <TabPanel value={selectedTab} index={0}>
              <DailyDataChart
                data={dailyData}
                selectedOpsData={selectedOpsData}
                selectedPopData={selectedPopData}
                chartHeight={chartHeight}
                chartWidth={chartWidth}
                renderUuid={dailyDataUuid}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <CallVolumeChart data={summaryData} chartHeight={chartHeight} chartWidth={chartWidth} renderUuid={callVolumeUuid}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <DepletionScenarios data={summaryData} maxStaff={emsStaffCount} chartHeight={chartHeight} chartWidth={chartWidth} renderUuid={depletionScenariosUuid}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              <ScenarioStats data={summaryData} chartHeight={chartHeight} chartWidth={chartWidth} renderUuid={scenarioStatsUuid}/>
            </TabPanel>
          </div>
          <div
            id="tabSelectorContainer"
            className={classes.tabSelectorContainer}
          >
            <div style={{ position: "relative", margin: "auto" }}>
              <Button
                onClick={() => {
                  this.setState({ selectedTab: 0 });
                }}
              >
                <div
                  style={{ display: "inline-flex", flexDirection: "column" }}
                >
                  Daily Data
                  <div style={getTabButtonStyle(0)} />
                </div>
              </Button>
              <Button
                onClick={() => {
                  this.setState({ selectedTab: 1 });
                }}
              >
                <div
                  style={{ display: "inline-flex", flexDirection: "column" }}
                >
                  Call Volume Scenarios
                  <div style={getTabButtonStyle(1)} />
                </div>
              </Button>
              <Button
                onClick={() => {
                  this.setState({ selectedTab: 2 });
                }}
              >
                <div
                  style={{ display: "inline-flex", flexDirection: "column" }}
                >
                  Depletion Scenarios
                  <div style={getTabButtonStyle(2)} />
                </div>
              </Button>

              <Button
                onClick={() => {
                  this.setState({ selectedTab: 3 });
                }}
              >
                <div
                  style={{ display: "inline-flex", flexDirection: "column" }}
                >
                  Scenario Stats Table
                  <div style={getTabButtonStyle(3)} />
                </div>
              </Button>
            </div>
            {/* <AppBar position="static" id="tabSelectorBar" className={classes.tabSelectorBar}> */}
            {/* <Tabs
                value={selectedTab}
                onChange={this.selectedTabChanged}
                aria-label="Select Data View"
                variant="scrollable"
              >
                <Tab label="Daily Data" {...makeTabProps(0)} />
                <Tab label="Call Volume Scenarios" {...makeTabProps(1)} />
                <Tab label="Depletion Scenarios" {...makeTabProps(2)} />
                <Tab label="Scenario Stats Table" {...makeTabProps(3)} />
              </Tabs> */}
            {/*         </AppBar> */}
          </div>
        </div>
        <div id="controlsContainer" className={classes.controlsContainer}>
          <div className={classes.controls}>
            <Typography
              style={{
                color: "#FF0000",
                marginLeft: "4px",
                marginBottom: "8px",
              }}
            >
              <b>Algorithm Not Verified</b>
            </Typography>
            <FormControl className={classes.control}>
              <InputLabel>Preset</InputLabel>
              <NativeSelect
                className={classes.presetSelect}
                value={preset}
                name="preset"
                onChange={this.presetChanged}
                inputProps={{ "aria-label": "Preset" }}
              >
                <option value={"default"}>Default</option>
                <option value={"better"}>Better</option>
                <option value={"worse"}>Worse</option>
                <option value={"dire"}>Dire</option>
                <option value={"custom"}>Custom</option>
              </NativeSelect>
            </FormControl>
            <Tooltip title={servicePopulationTooltip} placement="left">
              <TextField
                className={classes.control}
                id="servicePopulation"
                label="Service Population"
                type="number"
                onChange={this.settingChanged}
                value={servicePopulation}
                inputProps={{ step: 1000, min: 0, max: 10000000000 }}
              />
            </Tooltip>
            <Tooltip title={transportRateTooltip} placement="left">
              <TextField
                className={classes.control}
                id="transportRate"
                label="Transport Rate (%)"
                type="number"
                onChange={this.settingChanged}
                value={transportRate}
                inputProps={{ step: 0.5, min: 0, max: 100 }}
              />
            </Tooltip>
            <Tooltip title={attackRateTooltip} placement="left">
              <TextField
                className={classes.control}
                id="attackRate"
                label="Attack Rate (%)"
                type="number"
                onChange={this.settingChanged}
                value={attackRate}
                inputProps={{ step: 0.5, min: 0, max: 100 }}
              />
            </Tooltip>
            <Tooltip title={doublingIntervalTooltip} placement="left">
              <TextField
                className={classes.control}
                id="doublingIntervalDays"
                label="Doubling Interval (Days)"
                type="number"
                onChange={this.settingChanged}
                value={doublingIntervalDays}
                inputProps={{ step: 0.1, min: 1, max: 365 }}
              />
            </Tooltip>
            <Tooltip title={emsContagionRateTooltip} placement="left">
              <TextField
                className={classes.control}
                id="emsContagionRate"
                label="EMS Contagion Rate (%)"
                type="number"
                onChange={this.settingChanged}
                value={emsContagionRate}
                inputProps={{ step: 0.5, min: 0, max: 100 }}
              />
            </Tooltip>
            <Tooltip title={emsStaffCountTooltip} placement="left">
              <TextField
                className={classes.control}
                id="emsStaffCount"
                label="EMS Staff Count"
                type="number"
                onChange={this.settingChanged}
                value={emsStaffCount}
                inputProps={{ step: 5, min: 5, max: 1000000 }}
              />
            </Tooltip>
            <Tooltip title={recoveryDaysTooltip} placement="left">
              <TextField
                className={classes.control}
                id="recoveryDays"
                label="Days to Recover"
                type="number"
                onChange={this.settingChanged}
                value={recoveryDays}
                inputProps={{ min: 0, max: 365 }}
              />
            </Tooltip>
            <Collapse in={selectedTab === 0}>
              <FormControl className={classes.control}>
                <InputLabel id="ops-data-label">Operations Data</InputLabel>
                <Select
                  labelId="ops-data-label"
                  id="selectedOpsData"
                  multiple
                  value={selectedOpsData}
                  onChange={this.selectedOpsDataChanged}
                  input={<Input id="select-ops-data" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <DeletableChip
                          key={value}
                          label={opsDataLabels[value]}
                          className={classes.chip}
                          onDelete={() => {
                            this.removeFromSelectedOpsData(value);
                          }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={menuProps}
                >
                  {opsDataValues.map((value) => (
                    <MenuItem
                      key={value}
                      value={value}
                      style={this.getStyles(value, selectedOpsData, theme)}
                    >
                      {opsDataLabels[value]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.control}>
                <InputLabel id="pop-data-label">Population Data</InputLabel>
                <Select
                  labelId="pop-data-label"
                  id="selectedPopData"
                  multiple
                  value={selectedPopData}
                  onChange={this.selectedPopDataChanged}
                  input={<Input id="select-pop-data" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <DeletableChip
                          key={value}
                          label={popDataLabels[value]}
                          className={classes.chip}
                          onDelete={() => {
                            this.removeFromSelectedPopData(value);
                          }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={menuProps}
                >
                  {popDataValues.map((value) => (
                    <MenuItem
                      key={value}
                      value={value}
                      style={this.getStyles(value, selectedPopData, theme)}
                    >
                      {popDataLabels[value]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Collapse>
            <Button
              onClick={this.resetSettings}
              variant="contained"
              style={{ marginTop: "16px", width: "100%" }}
            >
              Reset
            </Button>
            <Button
              onClick={this.copyLinkToChart}
              variant="contained"
              style={{ marginTop: "16px", width: "100%" }}
            >
              Copy Link To Chart
            </Button>
          </div>
        </div>
        <Collapse in={this.state.showDisclaimerPopup}>
          <div className={classes.disclaimerPopup}>
            <div className={classes.disclaimerDiv}>
              <Typography variant="h4" className={classes.disclaimerText}>
                By using this site you agree to the terms of our{" "}
                <Link
                  id="disclaimerLink"
                  to="/"
                  className={classes.disclaimerLink}
                  onClick={this.showDisclaimer}
                >
                  Disclaimer
                </Link>
                .
              </Typography>
            </div>
            <Button
              className={classes.disclaimerCloseButton}
              onClick={this.hideDisclaimer}
            >
              X
            </Button>
          </div>
        </Collapse>
        <Dialog open={this.state.showDisclaimerDialog || false}>
          <DialogContent>
            <Disclaimer />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ showDisclaimerDialog: false });
                this.hideDisclaimer();
              }}
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}

HomeComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export const Home = withStyles(styles)(withTheme(HomeComponent));

export default Home;
