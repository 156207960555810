import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles, withTheme } from "@material-ui/core";

import { opsDataLabels, popDataLabels } from "../services/AppConsts";

import c3 from "c3";
import "c3/c3.css";

const styles = (theme) => ({
  chart: {
    width: "100%",
  },
});

class DailyDataChartComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    this.updateChartSize();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.data !== prevProps.data ||
      this.props.maxX !== prevProps.maxX ||
      this.props.maxY !== prevProps.maxY ||
      this.props.renderUuid !== prevProps.renderUuid
    ) {
      this.renderC3Chart();
    }

    if (
      this.props.chartHeight !== prevProps.chartHeight ||
      this.props.chartWidth !== prevProps.chartWidth
    ) {
      this.updateChartSize();
    }
  };

  updateChartSize = () => {
    if (this.state.c3chart !== undefined) {
      this.state.c3chart.resize({
        height: this.props.chartHeight,
        width: this.props.chartWidth,
      });
    }
  };

  renderC3Chart = () => {
    const {
      data,
      selectedOpsData,
      selectedPopData,
      chartHeight,
      chartWidth,
    } = this.props;

    let days = ["x"];
    let maxX = 0;
    let maxY = 0;
    let chartColumns = [days];

    let opsColumns = 0;
    for (let j = 0; j < selectedOpsData.length; j++) {
      chartColumns.push([opsDataLabels[selectedOpsData[j]]]);
      opsColumns++;
    }

    let firstPopColumn = 1 + opsColumns;
    for (let j = 0; j < selectedPopData.length; j++) {
      chartColumns.push([popDataLabels[selectedPopData[j]]]);
    }

    for (var i = 0; i < data.length; i++) {
      let datum = data[i];
      maxX = datum.ordinalDay;
      days.push(datum.ordinalDay);
      for (let j = 0; j < selectedOpsData.length; j++) {
        let value = Number(datum[selectedOpsData[j]]).toFixed(2);
        maxY = Math.max(maxY, value);
        chartColumns[j + 1].push(value);
      }
      for (let j = 0; j < selectedPopData.length; j++) {
        let value = Number(datum[selectedPopData[j]]).toFixed(2);
        maxY = Math.max(maxY, value);
        chartColumns[firstPopColumn + j].push(value);
      }
    }

    let tickValues = [];
    let divisor = 30;

    for (let i = 1; i <= maxX / divisor; i++) {
      tickValues.push(i * divisor);
    }
    let c3chart = c3.generate({
      bindto: `#chart`,
      data: {
        x: "x",
        columns: chartColumns,
        type: "line",
      },
      zoom: {
        enabled: true,
      },
      legend: {
        position: "bottom",
      },
      axis: {
        x: {
          min: 0,
          max: maxX,
          tick: {
            values: tickValues,
          },
          height: 50,
          label: {
            text: "Elapsed Days (0 = First Clinical Presentation)",
            position: "inner-left",
          },
          padding: { left: 0 },
        },
        y: {
          max: maxY,
          min: 0,
          padding: { top: 60, bottom: 0 },
          tick: {
            format: function (d) {
              if (d >= 1000000) {
                return `${d / 1000000}m`;
              } else if (d >= 1000) {
                return `${d / 1000}k`;
              } else {
                return d;
              }
            },
          },
        },
      },
      point: {
        show: false,
      },
      grid: {
        x: {
          show: true,
        },
        y: {
          show: true,
        },
      },
      size: {
        height: chartHeight,
        width: chartWidth,
      },
      padding: {
        top: 10,
        bottom: -20,
      },
      tooltip: {
        format: {
          title: function (d) {
            return `Day ${d}`;
          },
          value: function (value, ratio, id) {
            if (value >= 1000000) {
              return `${Number(value / 1000000).toFixed(2)}m`;
            } else if (value >= 1000) {
              return `${Number(value / 1000).toFixed(2)}k`;
            } else {
              return value;
            }
          },
        },
      },
    });
    this.setState({ c3chart: c3chart });
  };

  render = () => {
    return <div id="chart" />;
  };
}

DailyDataChartComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export const DailyDataChart = withStyles(styles)(
  withTheme(DailyDataChartComponent)
);

export default DailyDataChart;
