import React from "react";

import { makeStyles, Typography } from "@material-ui/core";
import Disclaimer from "./Disclaimer";

const useStyles = makeStyles(theme => ({
  container: { width: "75%", margin: "auto" },
  helpDiv: {},
  disclaimerDiv: { marginTop: "16px" }
}));

export function Help(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.helpDiv}>
        <p>
          <Typography variant="h5">
            Hello, and welcome to Levrum’s COVID-19 effects simulator. This is a
            tool to help you gauge the impacts of different epidemiology
            parameters for the pandemic on your organization’s ability to
            respond, based upon disease incidence progression in your community,
            and the rates of exposure and transmission to your operations staff.
          </Typography>
        </p>
        <p>
          <Typography variant="h6">
            A couple of tips for using the simulator:
          </Typography>
          We have preprogrammed several different potential scenarios based on
          observed conditions in different areas. These scenarios are available
          from the Preset dropdown. Your settings will be saved between
          sessions. Pressing the Reset button will return the application to its
          default settings. Clicking the Copy Link to Chart button will copy a
          link to the clipboard which contains the current settings so that you
          may share your simulation with others.
        </p>
        <Typography variant="h6">
          The tool enables you to set the following parameters:
        </Typography>
        <li>
          Service Population: the number of people covered by your fire/EMS
          service
        </li>
        <li>
          Transport Rate: the percentage of people affected by COVID-19 whom your
          agency transports (or contacts, if you are not a transport agency)
        </li>
        <li>
          Attack Rate: also called the “community attack rate,” this parameter
          defines the upper limit of the percentage of people potentially
          affected by the disease.
        </li>
        <li>
          Doubling Interval (Days): the time required for the incidence in the
          general population to double at the outset of the disease outbreak.
          (The rate of growth will slow as the contagion progresses).
        </li>
        <li>
          EMS Contagion Rate: the percentage of contacts between your staff and
          affected community members that result in disease transmission to your
          staff.
        </li>
        <li>
          EMS Staff Count: the number of professionals in your organization who
          will respond to potential COVID-19 incidents.
        </li>
        <li>
          Days to Recover: the average time required for full recovery from the
          disease. For the purposes of this simulation, this is assumed to be
          identical between fire/EMS staff and the general population.
        </li>
      </div>
      <div className={classes.disclaimerDiv}>
        <Disclaimer />
      </div>
    </div>
  );
}
export default Help;
