export const opsDataLabels = {
  responses: "COVID Transports",
  staffExposures: "Total Staff Exposures",
  staffOutOfService: "Active EMS Cases",
  staffRecovered: "Staff Recovered",
  staffAvailable: "Staff Fit For Duty",
  newCasesStaff: "New EMS Cases",
  newRecoveriesStaff: "New EMS Recoveries",
};

export const opsDataValues = [
  "responses",
  "staffExposures",
  "staffOutOfService",
  "staffRecovered",
  "staffAvailable",
  "newCasesStaff",
  "newRecoveriesStaff",
];

export const popDataLabels = {
  popSusceptible: "Population Susceptible",
  popContagious: "Population Contagious",
  popRecovered: "Population Recovered",
  popDeceased: "Population Deceased",
  newCasesPop: "New Population Cases",
  newRecoveriesPop: "New Population Recoveries",
  newDeathsPop: "New Population Deaths",
  popCumAffected: "Total Population Affected",
};

export const popDataValues = [
  "popSusceptible",
  "popContagious",
  "popRecovered",
  "popDeceased",
  "newCasesPop",
  "newRecoveriesPop",
  "newDeathsPop",
  "popCumAffected",
];


export const presets = {
  default: {
    preset: "default",
    attackRate: 45,
    doublingIntervalDays: 4,
    emsContagionRate: 5,
    recoveryDays: 14,
    transportRate: 5,
  },
  better: {
    preset: "better",
    attackRate: 35,
    doublingIntervalDays: 6,
    emsContagionRate: 3,
    recoveryDays: 10,
    transportRate: 5,
  },
  worse: {
    preset: "worse",
    attackRate: 50,
    doublingIntervalDays: 3,
    emsContagionRate: 6,
    recoveryDays: 18,
    transportRate: 6,
  },
  dire: {
    preset: "dire",
    attackRate: 55,
    doublingIntervalDays: 2.8,
    emsContagionRate: 7,
    recoveryDays: 21,
    transportRate: 7.5,
  },
  custom: {
    preset: "custom",
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const defaultSettings = {
  attackRate: 45,
  doublingIntervalDays: 4,
  emsContagionRate: 5,
  recoveryDays: 14,
  transportRate: 5,
  servicePopulation: 100000,
  emsStaffCount: 75,
  selectedTab: 0,
  selectedPopData: [],
  preset: "default",
  selectedOpsData: [
    "responses",
    "newCasesStaff",
    "staffOutOfService",
    "staffAvailable",
  ],
};