import React, { Component } from "react";

import { withStyles, withTheme } from "@material-ui/core";

import { opsDataLabels, popDataLabels } from "../services/AppConsts";

import c3 from "c3";
import "c3/c3.css";

const styles = (theme) => {};

class CallVolumeChartComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {};

  componentWillUnmount = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.data !== prevProps.data ||
      this.props.renderUuid !== prevProps.renderUuid) {
      this.renderC3Chart();
    }

    if (
      this.props.chartHeight !== prevProps.chartHeight ||
      this.props.chartHeight !== prevProps.chartHeight
    ) {
      this.updateChartSize();
    }
  };

  renderC3Chart = () => {
    const { data, chartHeight, chartWidth } = this.props;

    let days = ["x"];
    let maxX = 0;
    let maxY = 0;
    let chartColumns = [days];

    let worstDay = undefined;
    let bestCase = 10000000000;
    let worstCase = 0;

    for (var i = 0; i < data.length; i++) {
      let scenario = data[i];
      let dailyPredictions = scenario.dailyPredictions;
      if (!dailyPredictions) {
        continue;
      }
      maxY = scenario.peakDailyTransports;

      if (i === 3) {
        worstDay = scenario.peakDailyTransportsDay;
      }

      bestCase = Math.min(bestCase, scenario.peakDailyTransports);
      worstCase = Math.max(worstCase, scenario.peakDailyTransports);

      let column = [];
      column.push(scenario.scenarioLabel.trim());

      for (var j = 0; j < dailyPredictions.length; j++) {
        let day = dailyPredictions[j];
        maxX = day.ordinalDay;
        if (i === 0) {
          days.push(day.ordinalDay);
        }
        column.push(day.responses);
      }

      chartColumns.push(column);
    }

    let tickValues = [];
    let divisor = 30;

    for (let i = 1; i <= maxX / divisor; i++) {
      tickValues.push(i * divisor);
    }
    let c3chart = c3.generate({
      bindto: `#callVolumeChart`,
      data: {
        x: "x",
        columns: chartColumns,
        type: "line",
      },
      zoom: {
        enabled: true,
      },
      legend: {
        position: "bottom",
      },
      axis: {
        x: {
          min: 0,
          max: maxX,
          tick: {
            values: tickValues,
          },
          height: 50,
          label: {
            text: "Elapsed Days (0 = First Clinical Presentation)",
            position: "inner-left",
          },
          padding: { left: 0 },
        },
        y: {
          max: maxY,
          min: 0,
          padding: { top: 60, bottom: 0 },
          label: {
            text: "Transports Per Day",
            position: "outer-middle"
          },
          tick: {
            format: function (d) {
              if (d >= 1000000) {
                return `${d / 1000000}m`;
              } else if (d >= 1000) {
                return `${d / 1000}k`;
              } else {
                return d;
              }
            },
          },
        },
      },
      point: {
        show: false,
      },
      grid: {
        x: {
          show: true,
          lines: [
            {value: worstDay, text: "Worst Staffing Day", position: "start"}
          ]
        },
        y: {
          show: true,
          lines: [
            {value: bestCase, class: 'min', text: "Best Case", position: "start"},
            {value: worstCase, class: 'max', text: "Worst Case", position: "start"},
          ]
        },
      },
      size: {
        height: chartHeight,
        width: chartWidth,
      },
      padding: {
        top: 10,
        bottom: -20,
      },
      tooltip: {
        format: {
          title: function (d) {
            return `Day ${d}`;
          },
          value: function (value, ratio, id) {
            if (value >= 1000000) {
              return `${Number(value / 1000000).toFixed(2)}m`;
            } else if (value >= 1000) {
              return `${Number(value / 1000).toFixed(2)}k`;
            } else {
              return value;
            }
          },
        },
      },
    });
    this.setState({ c3chart: c3chart });
  }

  updateChartSize = () => {
    if (this.state.c3chart !== undefined) {
      this.state.c3chart.resize({
        height: this.props.chartHeight,
        width: this.props.chartWidth,
      });
    }
  }

  render = () => {
    return <React.Fragment>
      <div id="callVolumeChart" />
    </React.Fragment>;
  };
}

export const CallVolumeChart = withStyles(styles)(
  withTheme(CallVolumeChartComponent)
);

export default CallVolumeChart;
