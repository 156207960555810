import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? "https://localhost:5001/covid"
    : "/covid";

export default class Api {
  static async getAlgorithms() {
    try {
      let response = await axios.get(
        `${API_BASE_URL}/Calculation/list-algorithms`
      );
      return response.data;
    } catch {
      return null;
    }
  }

  static async getData(
    algorithm,
    attackRate,
    doublingIntervalDays,
    emsContagionRate,
    emsStaffCount,
    recoveryDays,
    servicePopulation,
    transportRate
  ) {
    try {
      let queryString = `${API_BASE_URL}/Calculation/${algorithm}?`;
      queryString += `attackRate=${attackRate}`;
      queryString += `&doublingIntervalDays=${doublingIntervalDays}`;
      queryString += `&emsContagionRate=${emsContagionRate}`;
      queryString += `&emsStaffCount=${emsStaffCount}`;
      queryString += `&recoveryDays=${recoveryDays}`;
      queryString += `&servicePopulation=${servicePopulation}`;
      queryString += `&transportRate=${transportRate}`;

      let response = await axios.get(queryString);
      return response.data;
    } catch {
      return null;
    }
  }

  static async submitContactInfo(first, last, email, phone) {
    try {
      let response = await axios.get(`${API_BASE_URL}/log-contact?${first}%20${last}%20${email}%20${phone}`);
      return response.data;
    } catch {
      return null;
    }
  }
}
