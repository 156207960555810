import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Help } from "./components/Help";

import './custom.css'
import './App.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Switch>
          <Route exact path="/help" component={Help} />        
          <Route path="/:encodedOptions" component={Home} />
          <Route path='/' component={Home} />
        </Switch>
      </Layout>
    );
  }
}
