import React, { Component } from "react";

import { Chip } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Cancel";

export class DeletableChip extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { onDelete, value, label, className } = this.props;
    return (
      <Chip
        value={value}
        deleteIcon={      <div
          onMouseDown={event => {
            if (!this.props.disabled && event.button === 0) {
              event.stopPropagation();
              onDelete(value);
            }
          }}
        >
          <DeleteIcon
            className="MuiChip-deleteIcon"
            style={{ margin: "0px 5px 0px 0px" }}
          />
        </div>}
        label={label}
        className={className}
        {...this.props}
      />
    );
  };
}

export default DeletableChip;
